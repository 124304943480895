import React from 'react';
import styled from 'styled-components';
import { DEFAULT_AVATAR } from '../../avatar/const';
import DiscountPrice from '../../section/DiscountPrice';
import { calculateDiscountPercentage } from '../../section/util';
import { theme } from '../../../styles/theme';
import Skeleton from 'react-loading-skeleton';
import { ITEMS } from '../../../route/constant';
import { Link, useNavigate } from 'react-router-dom';
import { ImgStyled } from '../../../styles/ImgStyled';
import { DESIGNERS } from '../../../route/constant';
import { removeSpecialCharactersAndSpaces } from '../../section/util';

const ItemCardMain = ({ isMobile, isSkeletonLoading, goTo, data, clickCart, clickLike, value }) => {
  const checkLocation = value === 'designerList' ? 'fromDesignerSection' : false;
  const imgCheck =
    !data.product_listImg || data.product_listImg === '' || data.product_listImg === null;
  const SKELETON_PADDINGTOP = '100%';
  const height = isMobile ? 300 : 460;
  const navigate = useNavigate();
  const studioName = data.studio_name;
  const studioId = data.id_studio;
  const onClickDesignerPage = (e, reward, productId, studioName) => {
    if (reward) {
      goTo(e, ITEMS + '/' + productId);
    } else {
      navigate(`${DESIGNERS}/${removeSpecialCharactersAndSpaces(studioName)}`, {
        state: { id: studioId, location: checkLocation },
      });
    }
  };

  return (
    <div className='shopItemCard' id='ItemCard'>
      <>
        {!isSkeletonLoading ? (
          <>
            <div className='aspectRatio'>
              {imgCheck ? (
                <ImgStyled
                  src='/assets/img/2.0WebImg/content/coming_soon.jpg'
                  alt=''
                  style={{ borderRadius: 8, height: '100%' }}
                />
              ) : (
                <div className='imgWrap'>
                  <div className='hover'>
                    <button
                      className='designerImg'
                      onClick={() =>
                        navigate(`${DESIGNERS}/${removeSpecialCharactersAndSpaces(studioName)}`, {
                          state: { id: studioId },
                        })
                      }
                    >
                      {!data.reward && (
                        <ImgStyled
                          className='designerImg2'
                          src={data?.designer_img || DEFAULT_AVATAR}
                          alt=''
                        />
                      )}
                    </button>
                    <button
                      tabIndex='0'
                      type='button'
                      className={`buttonFavorite ${data.my_like ? 'active' : ''}`}
                      title='add this item to the favorite list'
                      onClick={() => clickLike(data.product_id, data.product_like_count)}
                      style={{ bottom: !data?.reward ? '70px' : '20px' }}
                    />
                    {!data.reward && (
                      <button
                        tabIndex='0'
                        type='button'
                        className={`buttonCart ${data.in_cart && 'active'}`}
                        title='app this item to the cart'
                        onClick={() => clickCart(data.finalPrice, data.product_id, data.in_cart)}
                      />
                    )}
                    <Img height={height}>
                      <ImgStyled
                        src={data?.product_listImg2 || data?.product_listImg}
                        className='itemImg1 over'
                        alt=''
                      />
                    </Img>
                  </div>
                  <Link
                    to={ITEMS + '/' + data.product_id}
                    className='itemLinkPart1'
                    title='item detail'
                    onClick={e => goTo(e, ITEMS + '/' + data.product_id, data.product_id)}
                  >
                    <div className='normal'>
                      {/* 독점 표시*/}
                      {data.exclusive && !data.reward && (
                        <div className='itemLabel exclusive' style={{ color: '#FAFAFA' }}>
                          Fab<span style={{ color: '#28CE40' }}>365</span> Only
                        </div>
                      )}
                      {data.reward && (
                        <>
                          <div className='itemLabel reward'>Reward</div>
                          <div className='ticket'>
                            <div className='hiddenTxt'>Ticket</div>
                            <div className='amount'>{data.reward_qty}</div>
                          </div>
                        </>
                      )}
                    </div>
                    <Img height={height}>
                      <ImgStyled
                        src={
                          data.product_listImg || data.product_listImg !== ''
                            ? data.product_listImg
                            : '/assets/img/2.0WebImg/content/coming_soon.jpg'
                        }
                        className='itemImg1'
                        alt=''
                      />
                    </Img>
                  </Link>
                </div>
              )}
            </div>

            <button
              className='title'
              onClick={e => onClickDesignerPage(e, data.reward, data.product_id, data.studio_name)}
            >
              {!data.reward ? data.studio_name : 'FAB365'}
            </button>

            <Link
              to='#'
              className='itemLinkPart2'
              title='item detail'
              onClick={e => goTo(e, ITEMS + '/' + data.product_id)}
            >
              <div className='subtitle'>{data.product_name}</div>

              <div className='priceRow'>
                <>
                  <div className='price'>
                    {/* {!data.reward ? `$ $` : 'Free'} */}
                    {!data.reward ? (
                      data.finalPrice && data.originPrice && data.discountRate ? (
                        <DiscountPrice
                          finalPrice={data.finalPrice}
                          originPrice={data.originPrice}
                          discountRate={data.discountRate}
                        />
                      ) : null
                    ) : (
                      'Free'
                    )}
                  </div>
                  {/* discount 값이 있을 때만 표시 */}
                  {data?.isDiscount && (
                    <>
                      <div className='originalPrice'>$ {data.originPrice}</div>
                      <div className='discount'>
                        {calculateDiscountPercentage(data.originPrice, data.finalPrice)}%
                      </div>
                    </>
                  )}
                </>
              </div>

              <div className='etc'>
                <button tabIndex='0' type='button' className='favorites'>
                  <span className='hiddenTxt'>favorites</span>
                  {data.product_like_count || 0}
                </button>
                <button tabIndex='0' type='button' className='comments'>
                  <span className='hiddenTxt'>comments</span>
                  {data.product_comment_count || 0}
                </button>
                <button tabIndex='0' type='button' className='pictures'>
                  <span className='hiddenTxt'>pictures</span>
                  {data.review_count || 0}
                </button>
              </div>
            </Link>
          </>
        ) : (
          <>
            <Skeleton
              style={{
                paddingTop: SKELETON_PADDINGTOP,
                borderRadius: '8px',
              }}
              baseColor={theme.skeleton_gray}
              highlightColor={theme.skeleton_light_gray}
            />
            <Skeleton
              className='skeleton--title'
              width={160}
              baseColor={theme.skeleton_dark_gray}
              highlightColor={theme.sleleton_dark_gray_highlight}
            />
            <Skeleton
              className='skeleton--subtitle'
              width={200}
              baseColor={theme.skeleton_dark_gray}
              highlightColor={theme.sleleton_dark_gray_highlight}
            />

            <Skeleton
              className='price'
              width={120}
              style={{ marginTop: 20 }}
              baseColor={theme.skeleton_dark_gray}
              highlightColor={theme.sleleton_dark_gray_highlight}
            />

            <Skeleton
              className='skeleton--etc'
              width={200}
              height={11}
              baseColor={theme.skeleton_dark_gray}
              highlightColor={theme.sleleton_dark_gray_highlight}
            />
          </>
        )}
      </>
    </div>
  );
};

export default ItemCardMain;

const Img = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
