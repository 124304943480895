import { useContext, useEffect, useState } from 'react';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Signup from './Signup';
import UserContext from '../../context/user/user';
import FacebookNotice from './FacebookNotice';
import ClearSession from './ClearSession';
import Footer2 from './Footer2';
import Header2 from './Header2';
import { useLocation } from 'react-router-dom';
import { ls } from '../../constant';
import { MAIN } from '../../route/constant';

import { useRecoilState } from 'recoil';
import {
  scrollTo,
  selectedTabAtom,
  designerPaginationAtom,
  commentPrevAtom,
} from '../../atom/atom';
import EeaModal from '../../component/eea/EeaModal';
import { getUserInfo } from '../../action/request';

const Layout = ({ children }) => {
  const { state: userState, action: userAction } = useContext(UserContext);
  const [position, setPosition] = useState(0);
  const [showMenu, setShowMenu] = useState(true);
  const [scroll, setScroll] = useRecoilState(scrollTo);
  const [, setPageNation] = useRecoilState(designerPaginationAtom);
  const [, setSelectedTab] = useRecoilState(selectedTabAtom);
  const [, setPrevComment] = useRecoilState(commentPrevAtom);

  const location = useLocation();
  const isHome = location.pathname === MAIN;
  const isDesigners =
    location.pathname.includes('designers') || location.pathname.includes('items');
  const isCollections =
    location.pathname.includes('collections') || location.pathname.includes('items');

  const isSelectedTab = isDesigners || location.pathname.includes('reviews');

  function onScroll() {
    // 스크롤 위로 올릴 때
    if (position > window.scrollY || window.scrollY === 0) {
      setShowMenu(true);
    } else {
      // 스크롤 아래로 내릴 때
      setShowMenu(false);
    }

    setPosition(window.scrollY);
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [position]);

  useEffect(() => {
    return () => {
      if (isHome) {
        setScroll(position);
      }
    };
  }, [position]);

  useEffect(() => {
    if (!isHome) {
      localStorage.setItem(ls.prevScrollY, scroll);
      window.scrollTo(0, 0);
    }
  }, [scroll, isHome]);

  // 브라우저 창이 닫히면 localStorage > prevScrollY 를 초기화 한다.
  useEffect(() => {
    const handleBeforeUnload = () => {
      // localStorage의 특정 키를 초기화
      localStorage.removeItem(ls.prevScrollY);
      localStorage.removeItem('scrollDesigner');
      localStorage.removeItem('scroll');
      localStorage.removeItem(ls.itemPage);
      localStorage.removeItem(ls.itemSort);
      localStorage.removeItem(ls.itemType);
      setScroll(0);
    };

    // beforeunload 이벤트 리스너 등록
    window.addEventListener('beforeunload', handleBeforeUnload);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // useEffect를 한 번만 실행하도록 빈 배열을 전달

  // 로그인 시에 modal-opend 클래스네임 제거
  useEffect(() => {
    if (userState.isLogin) {
      document.body.classList.remove('modal-opened');
    }
  }, [userState.isLogin]);

  useEffect(() => {
    if (!isDesigners) {
      // 디자이너스 페이지네이션 값 초기화
      localStorage.removeItem(ls.specialDesignersPage);
      localStorage.removeItem(ls.designerItemListPage);
    }

    if (!isCollections) {
      // 콜렉션 페이지네이션 값 초기화
      localStorage.removeItem(ls.collectionPage);
      localStorage.removeItem(ls.collectionListPage);
    }

    document
      .querySelector('meta[property="og:image"]')
      .setAttribute(
        'content',
        'https://fab-img-v2.s3.ap-northeast-2.amazonaws.com/slide/D4571BD992A1A283F82F6A0A1F04545C.jpg'
      );
    if (!isSelectedTab || location.pathname === '/designers') {
      setSelectedTab(0);
      setPageNation(1);
      setPrevComment('null');
      localStorage.setItem('scrollDesigner', 0);
    }
  }, []);

  // Logined2.jsx 에서 check 할시 false => true로 바뀌면서
  // 반응이 느리게 오는 문제때문에 Layout으로 옮김
  // isLogin 초기값을 null로 한 다음 false , true 값이 들어올경우 화면을 띄어준다
  const checkIsLogin = async () => {
    const isLoginUsingToken =
      localStorage.getItem(ls.accessToken) !== 'undefined' &&
      localStorage.getItem(ls.accessToken) !== 'null' &&
      localStorage.getItem(ls.accessToken);
    if (isLoginUsingToken) {
      try {
        const {
          data: { data: userInfo },
        } = await getUserInfo();

        userAction.setIsLogin(true);
        userAction.setThumbnail(userInfo.thumbnail);
        userAction.setId(userInfo.id_user);
        userAction.setEmail(userInfo.email);
        userAction.setName(userInfo.name);
        userAction.setIsDesigner(userInfo.designer);
        userAction.setIdStudio(userInfo.id_studio);
        userAction.setStudioName(userInfo.studio_name);
        // setIsLoginAtom(true);
      } catch (e) {
        // 유저 정보 에러 날 때 > 로컬스토리지 초기화, user context 초기화
        localStorage.removeItem(ls.accessToken);
        localStorage.removeItem(ls.refreshToken);
        userAction.setInit();
      }
    } else {
      // 유저 정보 에러 날 때 > 로컬스토리지 초기화, user context 초기화
      localStorage.removeItem(ls.accessToken);
      localStorage.removeItem(ls.refreshToken);
      userAction.setInit();
    }
  };

  useEffect(() => {
    checkIsLogin();
  }, [userState.isLogin]);

  return (
    <>
      <ClearSession />

      {userState?.isLogin !== null && (
        <>
          <div className='wrapper'>
            <EeaModal />
            <Header2 showMenu={showMenu} position={position}></Header2>
            {children}
            <Footer2 />
          </div>

          <Login />
        </>
      )}
      <Signup />
      <ForgotPassword />
      <FacebookNotice />
    </>
  );
};

export default Layout;
