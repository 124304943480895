import React, { useContext, useState, useEffect } from 'react';
import { MY_PROFILE } from '../../route/constant';
import ModalContext from '../../context/modal/modal';
import UserContext from '../../context/user/user';
import { Link, useNavigate } from 'react-router-dom';
import ctgData from './categorylist.json';
import styled from 'styled-components';
import { removeSpecialCharactersAndSpaces } from '../section/util';
import { DESIGNERS } from '../../route/constant';
import { useRecoilState } from 'recoil';
import { ctgSortAtom } from '../../atom/atom';
import { SUPPLIES_URL } from '../common/contants';
function CategoryMenu({
  isOpenCategoryMenu,
  isOpenCategory,
  toggleCategoryMenu,
  toggleCategory,
  goTo,
  onLogout,
}) {
  const { action: modalAction } = useContext(ModalContext);
  const { state: userState } = useContext(UserContext);
  const navigate = useNavigate();
  const ctgList = ctgData.ctgDATA;
  const [, setCtgValue] = useRecoilState(ctgSortAtom);

  const onClick = () => {
    navigate(`${DESIGNERS}/${removeSpecialCharactersAndSpaces(userState.studioName)}`, {
      state: { id: userState.idStudio },
    });
    toggleCategoryMenu();
  };

  const onClickMove = (e, url) => {
    setCtgValue(false);
    goTo(e, url);
  };

  return (
    <>
      {/* <!-- category menu - start --> */}
      {/* <!-- categoryMenu에 active클래스 넣을시 화면에 노출 --> */}
      <div className={`categoryMenu ${isOpenCategoryMenu && 'active'}`}>
        <div className='title'>Category</div>
        <button
          tabIndex='0'
          className='cloeeMenuMo'
          title='close the Category Menu'
          onClick={toggleCategoryMenu}
        ></button>

        <div className='menuAll'>
          <div className={`buttonGroupRoW ${isOpenCategory && 'active'}`}>
            <button
              tabIndex='0'
              type='button'
              className={`buttonGroup ${isOpenCategory && 'active'}`}
              onClick={toggleCategory}
            >
              Category
            </button>
          </div>

          <div className='menuGroup'>
            {ctgList?.map((ctg, index) => {
              return (
                <Link to='#' className='menu' onClick={e => onClickMove(e, ctg.url)} key={index}>
                  <div className='icon'>
                    {ctg.iconValue === false ? ctg.icon : <img src={ctg.icon} alt={ctg.txt} />}
                  </div>
                  <div className='txt'>{ctg.txt}</div>
                </Link>
              );
            })}
          </div>

          <div className='buttonGroupRoW'>
            <a className='buttonGroupJustLink' href={SUPPLIES_URL} target='_blank' rel='noreferrer'>
              Supplies
            </a>
          </div>
        </div>
        <div className='bottomButtons'>
          {userState.isLogin ? (
            <>
              <button href='#' className='buttonWhite1in2' onClick={e => goTo(e, MY_PROFILE)}>
                My Page
              </button>
              <button href='#' className='buttonWhite1in2' onClick={onLogout}>
                Logout
              </button>
            </>
          ) : (
            <>
              <button
                href='#'
                className='buttonWhite1in2'
                onClick={() => {
                  toggleCategoryMenu();
                  modalAction.setIsLogin(true);
                }}
              >
                Login
              </button>

              <button
                href='#'
                className='buttonWhite1in2'
                onClick={() => {
                  toggleCategoryMenu();
                  modalAction.setIsSignup(true);
                }}
              >
                Sign Up
              </button>
            </>
          )}
        </div>
        {userState?.isDesigner ? <DesignerBtn onClick={() => onClick()}>Designer</DesignerBtn> : ''}
      </div>

      {/* <!-- dimm1에 active클래스 넣을시 화면에 노출 --> */}
      <button
        tabIndex='0'
        type='button'
        className={`dimm1 ${isOpenCategoryMenu && 'active'}`}
        onClick={toggleCategoryMenu}
        title='close the Category Menu'
      ></button>
      {/* <!-- category menu - end --> */}
    </>
  );
}

export default CategoryMenu;

const DesignerBtn = styled.div`
  display: block;
  border-radius: 6px;
  background: rgb(255, 255, 255);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  float: left;
  color: rgb(34, 34, 34) !important;
  margin-top: 5px;

  @media screen and (min-width: 700px) {
    display: none;
  }
`;
