import React, { useState } from 'react';
import { GtgSetting, ToggleContainer, ToggleBg, ToggleCircle, Scroll } from './eea';
import AcceptBtn from './AcceptBtn';
import SettingBtn from './SettingBtn';
import eealist from './data.json';
import { ImgStyled } from '../../styles/ImgStyled';

const EeaSetting = ({ setCookiesModal, cookiesModal, onClickAllCookies, onClickCloseEea }) => {
  const cookiesData = eealist.eeaData;
  const isMobile = window.innerWidth <= 700;
  const [isOn, setisOn] = useState([false, false, false, false]);
  const [isDetail, setIsDetail] = useState([false, false, false, false]);
  const [customize, setCustomize] = useState([]);

  const toggleHandler = (key, value) => {
    const newArr = [...isOn];
    newArr[key] = !isOn[key];
    setisOn(newArr);
    if (customize.length > 0) {
      if (customize.includes(value)) {
        const filter = customize.filter(el => el !== value);
        setCustomize(filter);
      } else {
        setCustomize([...customize, value]);
      }
    } else {
      setCustomize([value]);
    }
  };

  const onClickDetail = key => {
    const newArr = [...isDetail];
    newArr[key] = !isDetail[key];
    setIsDetail(newArr);
  };

  const onClickModalClose = () => {
    onClickAllCookies();
    setCookiesModal(false);
  };

  return (
    <GtgSetting id={EeaSetting}>
      <div className={isMobile ? ' settingMobile' : ' settingPc'}>
        <div className='settingHeader'>
          <ImgStyled src='/assets/img/cookies/logo.png' className='logo' />
          <button onClick={() => setCookiesModal(false)}>
            <ImgStyled src='/assets/img/cookies/close.png' className='close' />
          </button>
        </div>

        <div className='seettingContent'>
          <p>Cookie Consent Preference Center</p>

          <Scroll>
            <span className='content'>
              When you visit any of our websites, it may store or retrieve information on your
              browser, mostly in the form of cookies. This information might be about you, your
              preferences, or your device and is mostly used to make the site work as you expect it
              to. The information does not usually directly identify you, but it can give you a more
              personalized experience. Because we respect your right to privacy, you can choose not
              to allow some types of cookies. Click on the different category headings to find out
              more and manage your preferences. Please note, blocking some types of cookies may
              impact your experience of the site and the services we are able to offer.
            </span>
            <a className='policy' href='/assets/pdf/CookiesPolicy.pdf' target='_blank'>
              Cookie Policy.
            </a>

            <AcceptBtn
              width={'98%'}
              color={'#96FF85'}
              title={'Accept all cookies'}
              onClick={onClickModalClose}
            />
            <div className='manage'>
              <p>Manage Consent Preferences</p>
              {cookiesData?.map((cookie, key) => {
                return (
                  <div key={key} className='manageContent'>
                    <div className='header'>
                      <div className='flex' onClick={() => onClickDetail(key)}>
                        <ImgStyled
                          src={
                            isDetail[key]
                              ? '/assets/img/cookies/arrow_down.png'
                              : '/assets/img/cookies/arrow_up.png'
                          }
                          className='arrowimg'
                        />
                        <span className='title'>{cookie.title}</span>
                      </div>

                      {cookie.necessary ? (
                        <span className='title always'>Always Active</span>
                      ) : (
                        <>
                          <ToggleContainer onClick={() => toggleHandler(key, cookie.value)}>
                            <ToggleBg bg={isOn[key] ? '#dbffd5' : '#EAEAEA'} />
                            <ToggleCircle
                              bg={isOn[key] ? '#38BA22' : '#7C7C7C'}
                              left={isOn[key] ? 20 : 3}
                            />
                          </ToggleContainer>
                        </>
                      )}
                    </div>
                    <div>
                      {isDetail[key] ? (
                        <div className='contentsWrap'>
                          <span className='content'>{cookie.content}</span>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </Scroll>
        </div>

        <div className='fixed'>
          <div className={isMobile ? 'btnMobile' : 'btnPC'}>
            <SettingBtn
              isMobile={isMobile}
              setting={true}
              cookiesModal={cookiesModal}
              customize={customize}
              onClickCloseEea={onClickCloseEea}
            />
          </div>
        </div>
      </div>
    </GtgSetting>
  );
};

export default EeaSetting;
