import React from 'react';
import { GtgBtn } from './eea';

const AcceptBtn = ({ width, color, title, onClick, top }) => {
  return (
    <GtgBtn width={width} color={color} top={top} onClick={() => onClick()}>
      {title}
    </GtgBtn>
  );
};

export default AcceptBtn;
