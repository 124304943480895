import React from 'react';
import AcceptBtn from './AcceptBtn';
import { eeaCookies } from '../../util/eeaCookies';
import { useCookies } from 'react-cookie';

const SettingBtn = ({ onClickModal, isMobile, customize, cookiesModal, onClickCloseEea }) => {
  const btnWidth = isMobile ? '98%' : '48%';
  const [, setCookie] = useCookies(['eea_approval']);
  const onClickNecessaryCookies = () => {
    const necessaryData = {
      ad_user_data: 'granted',
      ad_storage: 'denied',
      ad_personalization: 'denied',
      functionality_storage: 'denied',
      analytics_storage: 'denied',
    };
    eeaCookies(necessaryData, setCookie);
    onClickCloseEea();
  };

  const onClickCustomize = customize => {
    const customData = {
      ad_user_data: 'granted',
      ad_storage: customize.includes('targeting') ? 'granted' : 'denied',
      ad_personalization: customize.includes('targeting') ? 'granted' : 'denied',
      functionality_storage: customize.includes('Functional') ? 'granted' : 'denied',
      analytics_storage: customize.includes('performance') ? 'granted' : 'denied',
    };
    eeaCookies(customData, setCookie);
    onClickCloseEea();
  };

  return (
    <>
      <AcceptBtn
        width={btnWidth}
        color={'#DDDDDD'}
        title={'Necessary cookies only'}
        onClick={() => onClickNecessaryCookies()}
      />
      <AcceptBtn
        width={btnWidth}
        color={'#DDDDDD'}
        title={'Customize settings'}
        onClick={() => (cookiesModal ? onClickCustomize(customize) : onClickModal())}
      />
    </>
  );
};

export default SettingBtn;
