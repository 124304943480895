import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ImgStyled } from '../../../styles/ImgStyled';
import DiscountPrice from '../../section/DiscountPrice';
import { calculateDiscountPercentage } from '../../section/util';
import { DEFAULT_AVATAR } from '../../avatar/const';
import styled from 'styled-components';
import { ITEMS, REVIEW } from '../../../route/constant';
import { getMainThumb } from '../../../util/image';
import { useRecoilState } from 'recoil';
import { paginationAtom } from '../../../atom/atom';

const ModelCard = ({ data, value, page, clickLike, clickCart }) => {
  const navigate = useNavigate();

  const reviewValue = value === 'review';
  const [, setPageNation] = useRecoilState(paginationAtom);

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const onClickGoto = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    setPageNation(page);
    localStorage.setItem('scrollDesigner', window.scrollY);

    if (reviewValue) {
      navigate(REVIEW + '/' + data.review_oid);
    } else {
      navigate(path);
    }
  };

  return (
    <Card className='shopItemCard'>
      <>
        {reviewValue ? (
          <Img>
            <img src={getMainThumb(data)} alt='' onClick={e => onClickGoto(e)} />
          </Img>
        ) : (
          <Img onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            {data?.product_listImg ? (
              <div onClick={e => onClickGoto(e, ITEMS + '/' + data.product_id)} className='imgWrap'>
                <ImgStyled alt='' src={data?.product_listImg} className='itemImg1' />
                <ImgStyled
                  alt=''
                  src={data?.product_listImg2 || data?.product_listImg}
                  className='itemImg1 over'
                />

                <div className='normal'>
                  {/* 독점 표시*/}
                  {data.exclusive && !data.reward && (
                    <div className='itemLabel exclusive' style={{ color: '#FAFAFA' }}>
                      Fab<span style={{ color: '#28CE40' }}>365</span> Only
                    </div>
                  )}
                  {data.reward && (
                    <>
                      <div className='itemLabel reward'>Reward</div>
                      <div className='ticket'>
                        <div className='hiddenTxt'>Ticket</div>
                        <div className='amount'>{data?.reward_qty}</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <ImgStyled
                src='/assets/img/2.0WebImg/content/coming_soon.jpg'
                alt='FAB365 COMING SOON'
              />
            )}

            {data?.product_listImg && isHovering ? (
              <div className='hover'>
                {!data.reward && (
                  <ImgStyled
                    className='designerProfile'
                    src={data?.designer_img || DEFAULT_AVATAR}
                    alt=''
                  />
                )}

                <button
                  tabIndex='0'
                  type='button'
                  className={`buttonFavorite ${data?.my_like && 'active'}`}
                  title='add this item to the favorite list'
                  onClick={() => clickLike(data.product_id, data?.id_studio)}
                  style={{ bottom: !data?.reward ? '60px' : '20px' }}
                />
                {data?.reward ? (
                  ''
                ) : (
                  <button
                    tabIndex='0'
                    type='button'
                    className={`buttonCart ${data.in_cart && 'active'}`}
                    title='app this item to the cart'
                    onClick={() =>
                      clickCart(data.finalPrice, data.product_id, data.in_cart, data.id_studio)
                    }
                  />
                )}
              </div>
            ) : (
              ''
            )}
          </Img>
        )}

        {reviewValue ? (
          <Info onClick={e => onClickGoto(e, ITEMS + '/' + data.product_id)}>
            <div style={{ paddingBottom: 10 }}>
              <Product className='name'>{data.user_name}</Product>
              <Product>{data.subject}</Product>
            </div>
          </Info>
        ) : (
          <Info onClick={e => onClickGoto(e, ITEMS + '/' + data.product_id)}>
            <div style={{ paddingBottom: 10 }}>
              <Product className='name'>{data.studio_name}</Product>
              <Product>{data?.product_name}</Product>
            </div>

            {reviewValue ? (
              ''
            ) : (
              <div className='priceRow'>
                <>
                  <div className='price'>
                    {!data?.reward ? (
                      <DiscountPrice
                        finalPrice={data.finalPrice}
                        originPrice={data.originPrice}
                        discountRate={data.discountRate}
                      />
                    ) : (
                      'Free'
                    )}
                  </div>

                  {data?.isDiscount && (
                    <>
                      <div className='originalPrice'>$ {data.originPrice}</div>
                      <div className='discount'>
                        {calculateDiscountPercentage(data.originPrice, data.finalPrice)}%
                      </div>
                    </>
                  )}
                </>
              </div>
            )}
          </Info>
        )}
      </>
      <div className='etc'>
        {reviewValue ? (
          <>
            <button tabIndex='0' type='button' className='favorites'>
              <span className='hiddenTxt'>favorites</span>
              {data.like_count || 0}
            </button>
            <button tabIndex='0' type='button' className='comments'>
              <span className='hiddenTxt'>comments</span>
              {data.comment_count || 0}
            </button>
          </>
        ) : (
          <>
            <button tabIndex='0' type='button' className='favorites'>
              <span className='hiddenTxt'>favorites</span>
              {data?.product_like_count || 0}
            </button>
            <button tabIndex='0' type='button' className='comments'>
              <span className='hiddenTxt'>comments</span>
              {data?.product_comment_count || 0}
            </button>
            <button tabIndex='0' type='button' className='pictures'>
              <span className='hiddenTxt'>pictures</span>
              {data?.review_count || 0}
            </button>
          </>
        )}
      </div>
    </Card>
  );
};

export default ModelCard;

const Card = styled.div`
  width: 100%;
  margin-bottom: 30px;
  position: relative;

  .itemLabel {
    position: absolute;
    left: 0;
    top: 0;
    background: #282432;
    height: 34px;
    line-height: 34px;
    text-align: left;
    padding: 0 11px 0 16px;
    z-index: 10;
    color: #fff !important;
    font-size: 14px;
    font-weight: 600;
  }

  .itemLabel.reward {
    background: #f7f15b;
    color: #151515 !important;
  }

  .itemLabel.reward:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -20px;
    border-top: 17px solid #f7f15b;
    border-left: 10px solid #f7f15b;
    border-right: 10px solid transparent;
    border-bottom: 17px solid transparent;
  }

  .ticket {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: 56px;
    height: 28px;
    background: url('/assets/img/2.0WebImg/ui/icon_ticket.png') center no-repeat;
    width: 56px;
    height: 28px;
    background-size: 56px 28px;
  }

  .ticket .amount {
    display: block;
    position: absolute;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background: #f7f15b;
    color: #151515;
    border-radius: 13px;
    color: #151515;
    font-size: 14px;
    font-weight: 500;
    top: -13px;
    right: -13px;
  }

  .buttonFavorite.active {
    background: #fff url('/assets/img/2.0WebImg/ui/favorite_on.svg') center no-repeat !important;
    background-size: 24px 24px !important;
  }

  .buttonCart.active {
    background: #fff url('/assets/img/2.0WebImg/ui/shopping_cart_on.svg') center no-repeat !important;
    background-size: 24px 24px !important;
  }

  @media screen and (max-width: 480px) {
    .hover .buttonFavorite {
      width: 35px;
      height: 35px;
    }

    .hover .buttonCart {
      width: 35px;
      height: 35px;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;

  .name {
    color: #171717;
    font-size: 14px;
    font-weight: 500;
    max-width: 300px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 480px) {
    .name {
      color: #171717;
      font-size: 14px;
    }
    .product-name {
      font-size: 14px;
    }
  }
`;

const Img = styled.div`
  position: relative;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    aspect-ratio: 1/1;
    background-color: #e8e0e0;
  }

  .hover .designerProfile {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    position: absolute;
    right: 12px;
    top: 10px;
  }

  .hover .buttonFavorite {
    display: block;
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #fff url('/assets/img/2.0WebImg/ui/favorite2.svg') center no-repeat;
    background-size: 24px 24px;
    right: 12px;
    bottom: 10px;
  }

  .hover .buttonCart {
    display: block;
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #fff url('/assets/img/2.0WebImg/ui/shopping_cart.svg') center no-repeat;
    background-size: 24px 24px;
    right: 12px;
    bottom: 12px;
  }
`;

const Product = styled.p`
  width: 100%;
  color: #686868;
  font-size: 18px;
  font-weight: 300;

  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
